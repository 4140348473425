<template>
	<div style="position:relative;">
		<api-data-source :api-path="apiPath" v-slot="apiProp">
			<div class="p-input-icon-left w-full">
				<i class="pi pi-search" />
				<InputText @focus="showResult" v-bind="$attrs" v-model="searchInput" class="w-full" />
			</div>
			<div v-show="show" class="search-result-holder" :class="menuClass">
				<div class="text-right p-2"><Button @click="hideResult()" class="p-button-text p-button-danger" icon="pi pi-times" /></div>
				<div class="result-list">
					<slot :close="hideResult" :records="getRecords(apiProp.response)" :searchText="searchText" :loading="apiProp.loading"></slot>
				</div>
			</div>
		</api-data-source>
	</div>
</template>
<script>
	import { utils } from "../utils";
	const debounce = utils.debounce;
	export default {
		props: {
			searchPage: {
				type: String,
				default: ''
			},
			menuClass: {
				type: String,
				default: ''
			},
			icon: {
				type: String,
				default: ''
			},
			autoClose: {
				type: Boolean,
				default: true
			},
		},
		data: function() {
			return {
				searchText: '',
				searchInput: '',
				show: false,
			}
		},
		computed: {
			apiPath () {
				let search = this.searchText;
				if(search){
					let qs = this.$utils.serializeQuery({ search });
					return this.searchPage + "?" + qs;
				}
				return "";
			},
		},
		methods: {
			getRecords (response) {
				if (response.records){
					return response.records;
				}
				return [];
			},
			showResult () {
				this.show = this.searchInput != '';
				return this.show;
			},
			hideResult () {
				this.show = false;
			},
		},
		watch: {
			searchText: function() {
                this.showResult();
			},
			searchInput: debounce(function (newVal) {
				this.searchText = newVal
			}, 500),
		},
	};
</script>