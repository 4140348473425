
export const AppMenus = {

  navbarTopRightItems: [],
  navbarTopLeftItems: [],
  navbarSideLeftItems: [
    {
      "to": "/home",
      "label": "Home",
      "icon": "pi pi-home text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/applicants",
      "label": "Credential Evaluation",
      "icon": "pi pi-star text-primary",
      "iconcolor": "",
      "target": "",

    },

    {
      "to": "",
      "label": "Programmes Management",
      "icon": "pi pi-book text-primary",
      "iconcolor": "",
      "target": "",
      "items": [

        {
          "to": "/caps_programmes_list",
          "label": "Programmes List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/caps_programmes_titles",
          "label": "Titles List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/caps_programme_sessions",
          "label": "Sessions List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/caps_programme_cohorts",
          "label": "Cohort List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/caps_programme_requisites",
          "label": "Requisites List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/caps_shs_courses",
          "label": "SHS Courses List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },

      ]
    },

    {
      "to": "",
      "label": "Institutions Management",
      "icon": "pi pi-building text-primary",
      "iconcolor": "",
      "target": "",
      "items": [

        {
          "to": "/caps_institutions_list",
          "label": "Tertiary Institutions List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },

        {
          "to": "/shs_list",
          "label": "SHS List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },

      ]
    },


    // {
    //   "to": "/caps_programmes_titles",
    //   "label": "Programmes Titles List",
    //   "icon": "pi pi-minus text-primary",
    //   "iconcolor": "",
    //   "target": "",

    // },
    // {
    //   "to": "/caps_programmes_list",
    //   "label": "Programmes List",
    //   "icon": "pi pi-book text-primary",
    //   "iconcolor": "",
    //   "target": "",

    // },
    // {
    //   "to": "/caps_institution_programmes",
    //   "label": "Institution Programmes",
    //   "icon": "pi pi-minus text-primary",
    //   "iconcolor": "",
    //   "target": "",

    // },
    {
      "to": "/minimum_entry_requirements",
      "label": "Minimum Entry Requirements",
      "icon": "pi pi-check text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/administrators",
      "label": "User Accounts",
      "icon": "pi pi-users text-primary",
      "iconcolor": "",
      "target": "",
    },

    {
      "to": "",
      "label": "Settings",
      "icon": "pi pi-cog text-primary",
      "iconcolor": "",
      "target": "",
      "items": [

        {
          "to": "/data_config/view/disability_list",
          "label": "Disability List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",

        },

        {
          "to": "/data_config/view/sports_list",
          "label": "Sport List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",
        },

        {
          "to": "/data_config/view/religions_list",
          "label": "Religion List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",
        },

        {
          "to": "/data_config/view/occupations_list",
          "label": "Occupation List",
          "icon": "pi pi-minus text-primary",
          "iconcolor": "",
          "target": "",
        },

      ]
    },
  ],
  caps_mall_statusItems: [
    { value: "Opened", label: "Opened" },
    { value: "Closed", label: "Closed" }
  ],
  yes_or_noItems: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ],
  approval_statusItems: [
    { value: "Approved", label: "Approved" },
    { value: "Declined", label: "Declined" }
  ],
  programme_levelItems: [
    { value: "Undergraduate", label: "Undergraduate" },
    { value: "Postgraduate", label: "Postgraduate" }
  ],

  account_typeItems: [
    { value: "Master Account", label: "Master Account" },
    { value: "Credential Evaluation", label: "Credential Evaluation Officer" },
    { value: "Accreditation", label: "Accreditation Officer" },
  ],
  account_statusItems: [{ "label": "Active", "value": "Active" }, { "label": "Inactive", "value": "Inactive" }, { "label": "Blocked", "value": "Blocked" }],

  shsgenderItems: [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Mixed", label: "Mixed" }
  ],

  shsresidencyItems: [
    { value: "Day", label: "Day" },
    { value: "Boarding", label: "Boarding" },
    { value: "Day & Boarding", label: "Day & Boarding" }
  ],

  regionsItems: [
    { "label": "Ahafo", "value": "Ahafo" },
    { "label": "Ashanti", "value": "Ashanti" },
    { "label": "Bono", "value": "Bono" },
    { "label": "Bono East", "value": "Bono East" },
    { "label": "Central", "value": "Central" },
    { "label": "Eastern", "value": "Eastern" },
    { "label": "Greater Accra", "value": "Greater Accra" },
    { "label": "North East", "value": "North East" },
    { "label": "Northern", "value": "Northern" },
    { "label": "Oti", "value": "Oti" },
    { "label": "Savannah", "value": "Savannah" },
    { "label": "Upper East", "value": "Upper East" },
    { "label": "Upper West", "value": "Upper West" },
    { "label": "Volta", "value": "Volta" },
    { "label": "Western", "value": "Western" },
    { "label": "Western North", "value": "Western North" }
  ],


  districtsItems: [
    {
      label: 'Ahafo',
      items: [
        { "label": "Asunafo North", "value": "Asunafo North" },
        { "label": "Asunafo South", "value": "Asunafo South" },
        { "label": "Asutifi North", "value": "Asutifi North" },
        { "label": "Asutifi South", "value": "Asutifi South" },
        { "label": "Tano South", "value": "Tano South" },
        { "label": "Tano North", "value": "Tano North" }
      ]
    },
    {
      label: 'Ashanti',
      items: [
        { "label": "Adansi Asokwa", "value": "Adansi Asokwa" },
        { "label": "Adansi North", "value": "Adansi North" },
        { "label": "Adansi South", "value": "Adansi South" },
        { "label": "Afigya Kwabre", "value": "Afigya Kwabre" },
        { "label": "Afigya Kwabre North", "value": "Afigya Kwabre North" },
        { "label": "Ahafo Ano North", "value": "Ahafo Ano North" },
        { "label": "Ahafo Ano South East", "value": "Ahafo Ano South East" },
        { "label": "Ahafo Ano South West", "value": "Ahafo Ano South West" },
        { "label": "Akrofuom", "value": "Akrofuom" },
        { "label": "Amansie Central", "value": "Amansie Central" },
        { "label": "Amansie South", "value": "Amansie South" },
        { "label": "Amansie West", "value": "Amansie West" },
        { "label": "Asante Akim Central", "value": "Asante Akim Central" },
        { "label": "Asante Akim North", "value": "Asante Akim North" },
        { "label": "Asante Akim South", "value": "Asante Akim South" },
        { "label": "Asokore Mampong", "value": "Asokore Mampong" },
        { "label": "Asokwa", "value": "Asokwa" },
        { "label": "Atwima Kwanwoma", "value": "Atwima Kwanwoma" },
        { "label": "Atwima Mponua", "value": "Atwima Mponua" },
        { "label": "Atwima Nwabiagya", "value": "Atwima Nwabiagya" },
        { "label": "Atwima Nwabiagya North", "value": "Atwima Nwabiagya North" },
        { "label": "Bekwai", "value": "Bekwai" },
        { "label": "Bosome Freho", "value": "Bosome Freho" },
        { "label": "Bosomtwe", "value": "Bosomtwe" },
        { "label": "Ejisu", "value": "Ejisu" },
        { "label": "Ejura-Sekyedumase", "value": "Ejura-Sekyedumase" },
        { "label": "Juaben", "value": "Juaben" },
        { "label": "Kumasi", "value": "Kumasi" },
        { "label": "Kwabre East", "value": "Kwabre East" },
        { "label": "Kwadaso", "value": "Kwadaso" },
        { "label": "Mampong", "value": "Mampong" },
        { "label": "Obuasi", "value": "Obuasi" },
        { "label": "Obuasi East", "value": "Obuasi East" },
        { "label": "Oforikrom", "value": "Oforikrom" },
        { "label": "Offinso North", "value": "Offinso North" },
        { "label": "Offinso South", "value": "Offinso South" },
        { "label": "Old Tafo", "value": "Old Tafo" },
        { "label": "Sekyere Afram Plains", "value": "Sekyere Afram Plains" },
        { "label": "Sekyere Central", "value": "Sekyere Central" },
        { "label": "Sekyere East", "value": "Sekyere East" },
        { "label": "Sekyere Kumawu", "value": "Sekyere Kumawu" },
        { "label": "Sekyere South", "value": "Sekyere South" },
        { "label": "Suame", "value": "Suame" }
      ]

    },
    {
      label: 'Bono',
      items: [
        { "label": "Banda", "value": "Banda" },
        { "label": "Berekum East", "value": "Berekum East" },
        { "label": "Berekum West", "value": "Berekum West" },
        { "label": "Dormaa Central", "value": "Dormaa Central" },
        { "label": "Dormaa East", "value": "Dormaa East" },
        { "label": "Dormaa West", "value": "Dormaa West" },
        { "label": "Jaman North", "value": "Jaman North" },
        { "label": "Jaman South", "value": "Jaman South" },
        { "label": "Sunyani", "value": "Sunyani" },
        { "label": "Sunyani West", "value": "Sunyani West" },
        { "label": "Tain", "value": "Tain" },
        { "label": "Wenchi", "value": "Wenchi" }
      ]
    },
    {
      label: 'Bono East',
      items: [
        { "label": "Atebubu-Amantin", "value": "Atebubu-Amantin" },
        { "label": "Kintampo North", "value": "Kintampo North" },
        { "label": "Kintampo South", "value": "Kintampo South" },
        { "label": "Nkoranza North", "value": "Nkoranza North" },
        { "label": "Nkoranza South", "value": "Nkoranza South" },
        { "label": "Pru East", "value": "Pru East" },
        { "label": "Pru West", "value": "Pru West" },
        { "label": "Sene East", "value": "Sene East" },
        { "label": "Sene West", "value": "Sene West" },
        { "label": "Techiman", "value": "Techiman" },
        { "label": "Techiman North", "value": "Techiman North" }
      ]
    },
    {
      label: 'Central',
      items: [
        { "label": "Abura Asebu Kwamankese", "value": "Abura Asebu Kwamankese" },
        { "label": "Agona East", "value": "Agona East" },
        { "label": "Agona West", "value": "Agona West" },
        { "label": "Ajumako Enyan Esiam", "value": "Ajumako Enyan Esiam" },
        { "label": "Asikuma / Odoben / Brakwa", "value": "Asikuma / Odoben / Brakwa" },
        { "label": "Assin Central", "value": "Assin Central" },
        { "label": "Assin North", "value": "Assin North" },
        { "label": "Assin South", "value": "Assin South" },
        { "label": "Awutu Senya East", "value": "Awutu Senya East" },
        { "label": "Awutu Senya West", "value": "Awutu Senya West" },
        { "label": "Cape Coast", "value": "Cape Coast" },
        { "label": "Effutu", "value": "Effutu" },
        { "label": "Ekumfi", "value": "Ekumfi" },
        { "label": "Gomoa Central", "value": "Gomoa Central" },
        { "label": "Gomoa East", "value": "Gomoa East" },
        { "label": "Gomoa West", "value": "Gomoa West" },
        { "label": "Hemang Lower Denkyira", "value": "Hemang Lower Denkyira" },
        { "label": "Komenda Edina Eguafo", "value": "Komenda Edina Eguafo" },
        { "label": "Mfantseman", "value": "Mfantseman" },
        { "label": "Twifo Ati-Morkwa", "value": "Twifo Ati-Morkwa" },
        { "label": "Upper Denkyira East", "value": "Upper Denkyira East" },
        { "label": "Upper Denkyira West", "value": "Upper Denkyira West" }
      ]
    },
    {
      label: 'Eastern',
      items: [
        { "label": "Achiase", "value": "Achiase" },
        { "label": "Abuakwa North", "value": "Abuakwa North" },
        { "label": "Abuakwa South", "value": "Abuakwa South" },
        { "label": "Akuapem North", "value": "Akuapem North" },
        { "label": "Akuapem South", "value": "Akuapem South" },
        { "label": "Akyemansa", "value": "Akyemansa" },
        { "label": "Asene Manso Akroso", "value": "Asene Manso Akroso" },
        { "label": "Asuogyaman", "value": "Asuogyaman" },
        { "label": "Atiwa East", "value": "Atiwa East" },
        { "label": "Atiwa West", "value": "Atiwa West" },
        { "label": "Ayensuano", "value": "Ayensuano" },
        { "label": "Birim Central", "value": "Birim Central" },
        { "label": "Birim North", "value": "Birim North" },
        { "label": "Birim South", "value": "Birim South" },
        { "label": "Denkyembour", "value": "Denkyembour" },
        { "label": "Fanteakwa North", "value": "Fanteakwa North" },
        { "label": "Fanteakwa South", "value": "Fanteakwa South" },
        { "label": "Kwahu Afram Plains North", "value": "Kwahu Afram Plains North" },
        { "label": "Kwahu Afram Plains South", "value": "Kwahu Afram Plains South" },
        { "label": "Kwahu East", "value": "Kwahu East" },
        { "label": "Kwahu South", "value": "Kwahu South" },
        { "label": "Kwahu West", "value": "Kwahu West" },
        { "label": "Kwaebibirem", "value": "Kwaebibirem" },
        { "label": "Lower Manya Krobo", "value": "Lower Manya Krobo" },
        { "label": "New Juaben North", "value": "New Juaben North" },
        { "label": "New Juaben South", "value": "New Juaben South" },
        { "label": "Nsawam Adoagyiri", "value": "Nsawam Adoagyiri" },
        { "label": "Okere", "value": "Okere" },
        { "label": "Suhum", "value": "Suhum" },
        { "label": "Upper Manya Krobo", "value": "Upper Manya Krobo" },
        { "label": "Upper West Akim", "value": "Upper West Akim" },
        { "label": "West Akim", "value": "West Akim" },
        { "label": "Yilo Krobo", "value": "Yilo Krobo" }
      ]
    },
    {
      label: 'Greater Accra',
      items: [
        { "label": "Accra", "value": "Accra" },
        { "label": "Ada East", "value": "Ada East" },
        { "label": "Ada West", "value": "Ada West" },
        { "label": "Ablekuma Central", "value": "Ablekuma Central" },
        { "label": "Ablekuma North", "value": "Ablekuma North" },
        { "label": "Ablekuma West", "value": "Ablekuma West" },
        { "label": "Adentan", "value": "Adentan" },
        { "label": "Ashaiman", "value": "Ashaiman" },
        { "label": "Ayawaso Central", "value": "Ayawaso Central" },
        { "label": "Ayawaso East", "value": "Ayawaso East" },
        { "label": "Ayawaso North", "value": "Ayawaso North" },
        { "label": "Ayawaso West", "value": "Ayawaso West" },
        { "label": "Ga Central", "value": "Ga Central" },
        { "label": "Ga East", "value": "Ga East" },
        { "label": "Ga North", "value": "Ga North" },
        { "label": "Ga South", "value": "Ga South" },
        { "label": "Ga West", "value": "Ga West" },
        { "label": "Korle Klottey", "value": "Korle Klottey" },
        { "label": "Kpone Katamanso", "value": "Kpone Katamanso" },
        { "label": "Krowor", "value": "Krowor" },
        { "label": "La Dade Kotopon", "value": "La Dade Kotopon" },
        { "label": "La Nkwantanang Madina", "value": "La Nkwantanang Madina" },
        { "label": "Ledzokuku", "value": "Ledzokuku" },
        { "label": "Ningo Prampram", "value": "Ningo Prampram" },
        { "label": "Okaikwei North", "value": "Okaikwei North" },
        { "label": "Shai-Osudoku", "value": "Shai-Osudoku" },
        { "label": "Tema", "value": "Tema" },
        { "label": "Tema West", "value": "Tema West" },
        { "label": "Weija Gbawe", "value": "Weija Gbawe" }
      ]
    },
    {
      label: 'North East',
      items: [
        { "label": "Bunkpurugu Nakpanduri", "value": "Bunkpurugu Nakpanduri" },
        { "label": "Chereponi", "value": "Chereponi" },
        { "label": "East Mamprusi", "value": "East Mamprusi" },
        { "label": "Mamprugu Moaduri", "value": "Mamprugu Moaduri" },
        { "label": "West Mamprusi", "value": "West Mamprusi" },
        { "label": "Yunyoo Nasuan", "value": "Yunyoo Nasuan" }
      ]
    },
    {
      label: 'Northern',
      items: [
        { "label": "Gushiegu", "value": "Gushiegu" },
        { "label": "Karaga", "value": "Karaga" },
        { "label": "Kpandai", "value": "Kpandai" },
        { "label": "Kumbungu", "value": "Kumbungu" },
        { "label": "Mion", "value": "Mion" },
        { "label": "Nanumba North", "value": "Nanumba North" },
        { "label": "Nanumba South", "value": "Nanumba South" },
        { "label": "Nanton", "value": "Nanton" },
        { "label": "Saboba", "value": "Saboba" },
        { "label": "Sagnerigu", "value": "Sagnerigu" },
        { "label": "Savelugu", "value": "Savelugu" },
        { "label": "Tamale", "value": "Tamale" },
        { "label": "Tatale Sangule", "value": "Tatale Sangule" },
        { "label": "Tolon", "value": "Tolon" },
        { "label": "Yendi", "value": "Yendi" },
        { "label": "Zabzugu", "value": "Zabzugu" }
      ]
    },
    {
      label: 'Oti',
      items: [
        { "label": "Biakoye", "value": "Biakoye" },
        { "label": "Guan", "value": "Guan" },
        { "label": "Jasikan", "value": "Jasikan" },
        { "label": "Kadjebi", "value": "Kadjebi" },
        { "label": "Krachi East", "value": "Krachi East" },
        { "label": "Krachi Nchumuru", "value": "Krachi Nchumuru" },
        { "label": "Krachi West", "value": "Krachi West" },
        { "label": "Nkwanta North", "value": "Nkwanta North" },
        { "label": "Nkwanta South", "value": "Nkwanta South" }
      ]
    },
    {
      label: 'Savannah',
      items: [
        { "label": "Bole", "value": "Bole" },
        { "label": "Central Gonja", "value": "Central Gonja" },
        { "label": "East Gonja", "value": "East Gonja" },
        { "label": "North East Gonja", "value": "North East Gonja" },
        { "label": "North Gonja", "value": "North Gonja" },
        { "label": "Sawla Tuna Kalba", "value": "Sawla Tuna Kalba" },
        { "label": "West Gonja", "value": "West Gonja" }
      ]
    },
    {
      label: 'Upper East',
      items: [
        { "label": "Bawku", "value": "Bawku" },
        { "label": "Bawku West", "value": "Bawku West" },
        { "label": "Bolgatanga", "value": "Bolgatanga" },
        { "label": "Bolgatanga East", "value": "Bolgatanga East" },
        { "label": "Binduri", "value": "Binduri" },
        { "label": "Bongo", "value": "Bongo" },
        { "label": "Builsa North", "value": "Builsa North" },
        { "label": "Builsa South", "value": "Builsa South" },
        { "label": "Garu", "value": "Garu" },
        { "label": "Kassena Nankana East", "value": "Kassena Nankana East" },
        { "label": "Kassena Nankana West", "value": "Kassena Nankana West" },
        { "label": "Nabdam", "value": "Nabdam" },
        { "label": "Pusiga", "value": "Pusiga" },
        { "label": "Talensi", "value": "Talensi" },
        { "label": "Tempane", "value": "Tempane" }
      ]
    },
    {
      label: 'Upper West',
      items: [
        { "label": "Daffiama Bussie Issa", "value": "Daffiama Bussie Issa" },
        { "label": "Jirapa", "value": "Jirapa" },
        { "label": "Lambussie Karni", "value": "Lambussie Karni" },
        { "label": "Lawra", "value": "Lawra" },
        { "label": "Nadowli Kaleo", "value": "Nadowli Kaleo" },
        { "label": "Nandom", "value": "Nandom" },
        { "label": "Sissala East", "value": "Sissala East" },
        { "label": "Sissala West", "value": "Sissala West" },
        { "label": "Wa", "value": "Wa" },
        { "label": "Wa East", "value": "Wa East" },
        { "label": "Wa West", "value": "Wa West" }
      ]
    },
    {
      label: 'Volta',
      items: [
        { "label": "Adaklu", "value": "Adaklu" },
        { "label": "Afadjato South", "value": "Afadjato South" },
        { "label": "Agotime Ziope", "value": "Agotime Ziope" },
        { "label": "Akatsi North", "value": "Akatsi North" },
        { "label": "Akatsi South", "value": "Akatsi South" },
        { "label": "Biakoye", "value": "Biakoye" },
        { "label": "Central Tongu", "value": "Central Tongu" },
        { "label": "Hohoe", "value": "Hohoe" },
        { "label": "Ho", "value": "Ho" },
        { "label": "Ho West", "value": "Ho West" },
        { "label": "Jasikan", "value": "Jasikan" },
        { "label": "Kadjebi", "value": "Kadjebi" },
        { "label": "Keta", "value": "Keta" },
        { "label": "Ketu North", "value": "Ketu North" },
        { "label": "Ketu South", "value": "Ketu South" },
        { "label": "Kpando", "value": "Kpando" },
        { "label": "Krachi East", "value": "Krachi East" },
        { "label": "Krachi Nchumuru", "value": "Krachi Nchumuru" },
        { "label": "Krachi West", "value": "Krachi West" },
        { "label": "Nkwanta North", "value": "Nkwanta North" },
        { "label": "Nkwanta South", "value": "Nkwanta South" },
        { "label": "North Dayi", "value": "North Dayi" },
        { "label": "North Tongu", "value": "North Tongu" },
        { "label": "South Dayi", "value": "South Dayi" },
        { "label": "South Tongu", "value": "South Tongu" }
      ]
    },
    {
      label: 'Western',
      items: [
        { "label": "Ellembelle", "value": "Ellembelle" },
        { "label": "Ahanta West", "value": "Ahanta West" },
        { "label": "Jomoro", "value": "Jomoro" },
        { "label": "Effia Kwesimintim", "value": "Effia Kwesimintim" },
        { "label": "Mpohor", "value": "Mpohor" },
        { "label": "Nzema East", "value": "Nzema East" },
        { "label": "Prestea Huni Valley", "value": "Prestea Huni Valley" },
        { "label": "Shama", "value": "Shama" },
        { "label": "SekondI-Takoradi", "value": "SekondI-Takoradi" },
        { "label": "Tarkwa Nsuaem", "value": "Tarkwa Nsuaem" },
        { "label": "Wassa Amenfi Central", "value": "Wassa Amenfi Central" },
        { "label": "Wassa Amenfi East", "value": "Wassa Amenfi East" },
        { "label": "Wassa Amenfi West", "value": "Wassa Amenfi West" },
        { "label": "Wassa East", "value": "Wassa East" }
      ]
    },
    {
      label: 'Western North',
      items: [
        { "label": "Aowin", "value": "Aowin" },
        { "label": "Bibiani Anhwiaso Bekwai", "value": "Bibiani Anhwiaso Bekwai" },
        { "label": "Bodi", "value": "Bodi" },
        { "label": "Bia East", "value": "Bia East" },
        { "label": "Juaboso", "value": "Juaboso" },
        { "label": "Sefwi Akontombra", "value": "Sefwi Akontombra" },
        { "label": "Sefwi Wiawso", "value": "Sefwi Wiawso" },
        { "label": "Suaman", "value": "Suaman" },
        { "label": "Bia West", "value": "Bia West" }
      ]
    }
  ],


  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: '',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}