
import { createRouter, createWebHashHistory } from 'vue-router';

export default async ({ store }) => {
  let routes = [
    //Dashboard routes

    //applicants routes
    {
      path: '/applicants',
      alias: '/applicants/:fieldName/:fieldValue',
      name: 'applicantslist',
      component: () => import('./pages/applicants/list.vue'),
      props: true
    },

    //accredited_institution_programmes routes
    {
      path: '/accredited_institution_programmes',
      alias: '/accredited_institution_programmes/:fieldName/:fieldValue',
      name: 'accredited_institution_programmeslist',
      component: () => import('./pages/accredited_institution_programmes/list.vue'),
      props: true
    },


    //institution_campuses routes
    {
      path: '/institution_campuses',
      alias: '/institution_campuses/:fieldName/:fieldValue',
      name: 'institution_campuseslist',
      component: () => import('./pages/institution_campuses/list.vue'),
      props: true
    },
    {
      path: '/institution_campuses/add',
      name: 'institution_campusesadd',
      component: () => import('./pages/institution_campuses/add.vue'),
      props: true
    },

    {
      path: '/institution_campuses/edit/:id',
      name: 'institution_campusesedit',
      component: () => import('./pages/institution_campuses/edit.vue'),
      props: true
    },

    //caps_programmes_titles routes
    {
      path: '/caps_programmes_titles',
      alias: '/caps_programmes_titles/:fieldName/:fieldValue',
      name: 'caps_programmes_titleslist',
      component: () => import('./pages/caps_programmes_titles/list.vue'),
      props: true
    },
    {
      path: '/caps_programmes_titles/add',
      name: 'caps_programmes_titlesadd',
      component: () => import('./pages/caps_programmes_titles/add.vue'),
      props: true
    },

    {
      path: '/caps_programmes_titles/edit/:id',
      name: 'caps_programmes_titlesedit',
      component: () => import('./pages/caps_programmes_titles/edit.vue'),
      props: true
    },



    //regions routes
    {
      path: '/regions',
      alias: '/regions/:fieldName/:fieldValue',
      name: 'regionslist',
      component: () => import('./pages/regions/list.vue'),
      props: true 
    },
    {
      path: '/regions/add',
      name: 'regionsadd',
      component: () => import('./pages/regions/add.vue'),
      props: true
    },

    {
      path: '/regions/edit/:id',
      name: 'regionsedit',
      component: () => import('./pages/regions/edit.vue'),
      props: true
    },


    //caps_programme_sessions routes
    {
      path: '/caps_programme_sessions',
      alias: '/caps_programme_sessions/:fieldName/:fieldValue',
      name: 'caps_programme_sessionslist',
      component: () => import('./pages/caps_programme_sessions/list.vue'),
      props: true
    },
    {
      path: '/caps_programme_sessions/add',
      name: 'caps_programme_sessionsadd',
      component: () => import('./pages/caps_programme_sessions/add.vue'),
      props: true
    },

    {
      path: '/caps_programme_sessions/edit/:id',
      name: 'caps_programme_sessionsedit',
      component: () => import('./pages/caps_programme_sessions/edit.vue'),
      props: true
    },


    //caps_programme_cohorts routes
    {
      path: '/caps_programme_cohorts',
      alias: '/caps_programme_cohorts/:fieldName/:fieldValue',
      name: 'caps_programme_cohortslist',
      component: () => import('./pages/caps_programme_cohorts/list.vue'),
      props: true
    },
    {
      path: '/caps_programme_cohorts/add',
      name: 'caps_programme_cohortsadd',
      component: () => import('./pages/caps_programme_cohorts/add.vue'),
      props: true
    },

    {
      path: '/caps_programme_cohorts/edit/:id',
      name: 'caps_programme_cohortsedit',
      component: () => import('./pages/caps_programme_cohorts/edit.vue'),
      props: true
    },

    //caps_programme_requisites routes
    {
      path: '/caps_programme_requisites',
      alias: '/caps_programme_requisites/:fieldName/:fieldValue',
      name: 'caps_programme_requisiteslist',
      component: () => import('./pages/caps_programme_requisites/list.vue'),
      props: true
    },
    {
      path: '/caps_programme_requisites/add',
      name: 'caps_programme_requisitesadd',
      component: () => import('./pages/caps_programme_requisites/add.vue'),
      props: true
    },

    {
      path: '/caps_programme_requisites/edit/:id',
      name: 'caps_programme_requisitesedit',
      component: () => import('./pages/caps_programme_requisites/edit.vue'),
      props: true
    },

    //caps_shs_courses routes
    {
      path: '/caps_shs_courses',
      alias: '/caps_shs_courses/:fieldName/:fieldValue',
      name: 'caps_shs_courseslist',
      component: () => import('./pages/caps_shs_courses/list.vue'),
      props: true
    },
    {
      path: '/caps_shs_courses/add',
      name: 'caps_shs_coursesadd',
      component: () => import('./pages/caps_shs_courses/add.vue'),
      props: true
    },

    {
      path: '/caps_shs_courses/edit/:id',
      name: 'caps_shs_coursesedit',
      component: () => import('./pages/caps_shs_courses/edit.vue'),
      props: true
    },



    //administrators routes
    {
      path: '/administrators',
      alias: '/administrators/:fieldName/:fieldValue',
      name: 'administratorslist',
      component: () => import('./pages/administrators/list.vue'),
      props: true
    },


    {
      path: '/administrators/view/:id',
      name: 'administratorsview',
      component: () => import('./pages/administrators/view.vue'),
      props: true
    },

    {
      path: '/account/edit',
      name: 'administratorsaccountedit',
      component: () => import('./pages/account/accountedit.vue'),
      props: true
    },

    {
      path: '/account',
      name: 'administratorsaccountview',
      component: () => import('./pages/account/accountview.vue'),
      props: true
    },

    {
      path: '/administrators/add',
      name: 'administratorsadd',
      component: () => import('./pages/administrators/add.vue'),
      props: true
    },

    {
      path: '/administrators/edit/:id',
      name: 'administratorsedit',
      component: () => import('./pages/administrators/edit.vue'),
      props: true
    },



    //applicants routes
    {
      path: '/applicants',
      alias: '/applicants/:fieldName/:fieldValue',
      name: 'applicantslist',
      component: () => import('./pages/applicants/list.vue'),
      props: true
    },


    {
      path: '/applicants/view/:id',
      name: 'applicantsview',
      component: () => import('./pages/applicants/view.vue'),
      props: true
    },

    {
      path: '/applicants/subview/:id',
      name: 'applicantssubview',
      component: () => import('./pages/applicants/subview.vue'),
      props: true
    },


    //caps_institution_programmes routes
    {
      path: '/caps_institution_programmes',
      alias: '/caps_institution_programmes/:fieldName/:fieldValue',
      name: 'caps_institution_programmeslist',
      component: () => import('./pages/caps_institution_programmes/list.vue'),
      props: true
    },


    {
      path: '/caps_institution_programmes/add',
      name: 'caps_institution_programmesadd',
      component: () => import('./pages/caps_institution_programmes/add.vue'),
      props: true
    },

    {
      path: '/caps_institution_programmes/edit/:id',
      name: 'caps_institution_programmesedit',
      component: () => import('./pages/caps_institution_programmes/edit.vue'),
      props: true
    },


    //caps_institutions_list routes
    {
      path: '/caps_institutions_list',
      alias: '/caps_institutions_list/:fieldName/:fieldValue',
      name: 'caps_institutions_listlist',
      component: () => import('./pages/caps_institutions_list/list.vue'),
      props: true
    },


    {
      path: '/caps_institutions_list/view/:id',
      name: 'caps_institutions_listview',
      component: () => import('./pages/caps_institutions_list/view.vue'),
      props: true
    },

    {
      path: '/caps_institutions_list/add',
      name: 'caps_institutions_listadd',
      component: () => import('./pages/caps_institutions_list/add.vue'),
      props: true
    },

    {
      path: '/caps_institutions_list/edit/:id',
      name: 'caps_institutions_listedit',
      component: () => import('./pages/caps_institutions_list/edit.vue'),
      props: true
    },

    {
      path: '/caps_institutions_list/viewmore/:id',
      name: 'caps_institutions_listviewmore',
      component: () => import('./pages/caps_institutions_list/viewmore.vue'),
      props: true
    },


    //caps_programmes_list routes
    {
      path: '/caps_programmes_list',
      alias: '/caps_programmes_list/:fieldName/:fieldValue',
      name: 'caps_programmes_listlist',
      component: () => import('./pages/caps_programmes_list/list.vue'),
      props: true
    },


    {
      path: '/caps_programmes_list/view/:id',
      name: 'caps_programmes_listview',
      component: () => import('./pages/caps_programmes_list/view.vue'),
      props: true
    },

    {
      path: '/caps_programmes_list/add',
      name: 'caps_programmes_listadd',
      component: () => import('./pages/caps_programmes_list/add.vue'),
      props: true
    },

    {
      path: '/caps_programmes_list/edit/:id',
      name: 'caps_programmes_listedit',
      component: () => import('./pages/caps_programmes_list/edit.vue'),
      props: true
    },



    //shs_list routes
    {
      path: '/shs_list',
      alias: '/shs_list/:fieldName/:fieldValue',
      name: 'shs_listlist',
      component: () => import('./pages/shs_list/list.vue'),
      props: true
    },


    {
      path: '/shs_list/view/:id',
      name: 'shs_listview',
      component: () => import('./pages/shs_list/view.vue'),
      props: true
    },

    {
      path: '/shs_list/add',
      name: 'shs_listadd',
      component: () => import('./pages/shs_list/add.vue'),
      props: true
    },

    {
      path: '/shs_list/edit/:id',
      name: 'shs_listedit',
      component: () => import('./pages/shs_list/edit.vue'),
      props: true
    },



    //data_config
    {
      path: '/data_config/view/sports_list',
      name: 'data_configsports_list',
      component: () => import('./pages/data_config/sport_list.vue'),
      props: true
    },
    
    {
      path: '/data_config/view/disability_list',
      name: 'data_configdisability_list',
      component: () => import('./pages/data_config/disability_list.vue'),
      props: true
    },

 
    {
      path: '/data_config/view/religions_list',
      name: 'data_configreligions_list',
      component: () => import('./pages/data_config/religions_list.vue'),
      props: true
    },

 
    {
      path: '/data_config/view/occupations_list',
      name: 'data_configoccupations_list',
      component: () => import('./pages/data_config/occupations_list.vue'),
      props: true
    },



    //minimum_entry_requirements routes
    {
      path: '/minimum_entry_requirements',
      alias: '/minimum_entry_requirements/:fieldName/:fieldValue',
      name: 'minimum_entry_requirementslist',
      component: () => import('./pages/minimum_entry_requirements/list.vue'),
      props: true
    },


    {
      path: '/minimum_entry_requirements/view/:id',
      name: 'minimum_entry_requirementsview',
      component: () => import('./pages/minimum_entry_requirements/view.vue'),
      props: true
    },

    {
      path: '/minimum_entry_requirements/add',
      name: 'minimum_entry_requirementsadd',
      component: () => import('./pages/minimum_entry_requirements/add.vue'),
      props: true
    },

    {
      path: '/minimum_entry_requirements/edit/:id',
      name: 'minimum_entry_requirementsedit',
      component: () => import('./pages/minimum_entry_requirements/edit.vue'),
      props: true
    },


    //Password reset routes
    {
      path: '/index/forgotpassword',
      name: 'forgotpassword',
      component: () => import('./pages/index/forgotpassword.vue'),
      props: true
    },
    {
      path: '/index/resetpassword',
      name: 'resetpassword',
      component: () => import('./pages/index/resetpassword.vue'),
      props: true
    },
    {
      path: '/index/resetpassword_completed',
      name: 'resetpassword_completed',
      component: () => import('./pages/index/resetpassword_completed.vue'),
      props: true
    },



    {
      path: '/index/accountcreated',
      name: 'accountcreated',
      component: () => import('./pages/index/accountcreated.vue'),
      props: true
    },
    {
      path: '/index/accountinactive',
      name: 'accountinactive',
      component: () => import('./pages/index/accountinactive.vue'),
      props: true
    },
    {
      path: '/index/accountpending',
      name: 'accountpending',
      component: () => import('./pages/index/accountpending.vue'),
      props: true
    },
    {
      path: '/index/accountblocked',
      name: 'accountblocked',
      component: () => import('./pages/index/accountblocked.vue'),
      props: true
    },

    //Otp verify code routes
    {
      path: '/index/verifyotp',
      name: 'verifyotp',
      component: () => import('./pages/index/verifyotp.vue'),
      props: true
    },


    {
      path: '/error/forbidden',
      name: 'forbidden',
      component: () => import('./pages/errors/forbidden.vue'),
      props: true
    },
    {
      path: '/error/notfound',
      name: 'notfound',
      component: () => import('./pages/errors/pagenotfound.vue'),
      props: true
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('./pages/errors/pagenotfound.vue')
    }
  ];


  let loginToken = store.getters["auth/getLoginToken"];
  if (loginToken) {	//Token is available, user might still be logged in
    try {
      //fetch user data for the first time and saves in the vuex store
      await store.dispatch("auth/getUserData");
    }
    catch (e) {
      store.dispatch("auth/logout"); //token must have expired
    }
  }


  let user = store.state.auth.user;
  if (user) {
    routes.push({
      path: '/',
      alias: '/home',
      name: 'home',
      component: () => import(`./pages/home/home.vue`),
      props: true
    });
  }
  else {
    routes.push({
      path: '/',
      alias: '/index',
      name: 'index',
      component: () => import('./pages/index/index.vue'),
      props: true
    });
  }


  const router = createRouter({
    history: createWebHashHistory(),
    routes,
  });

  router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);
    next();
  });

  return router;
}
