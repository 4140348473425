import { createStore } from "vuex";
import { accredited_institution_programmes } from "./accredited_institution_programmes.js";
import { administrators } from "./administrators.js";
import appStore from "./app_store.js";
import { applicants } from "./applicants.js";
import { auth } from "./auth.js";
import { caps_institution_programmes } from "./caps_institution_programmes.js";
import { caps_institutions_list } from "./caps_institutions_list.js";
import { caps_programme_cohorts } from "./caps_programme_cohorts.js";
import { caps_programme_requisites } from "./caps_programme_requisites.js";
import { caps_programme_sessions } from "./caps_programme_sessions.js";
import { caps_programmes_list } from "./caps_programmes_list.js";
import { caps_programmes_titles } from "./caps_programmes_titles.js";
import { caps_shs_courses } from "./caps_shs_courses.js";
import { data_config } from "./data_config.js";
import { institution_campuses } from "./institution_campuses.js";
import { minimum_entry_requirements } from "./minimum_entry_requirements.js";
import { pageComponents } from "./page_components.js";
import { regions } from "./regions.js";
import { shs_list } from "./shs_list.js";


const { state, getters, mutations, actions } = appStore;

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    pageComponents,
    auth,
    accredited_institution_programmes,
    administrators,
    applicants,
    caps_institution_programmes,
    caps_institutions_list,
    caps_programmes_list,
    minimum_entry_requirements,
    institution_campuses,
    caps_programmes_titles,
    caps_programme_sessions,
    caps_programme_cohorts,
    caps_programme_requisites,
    caps_shs_courses,
    regions,
    shs_list,
    data_config,
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  //strict: process.env.DEV
});
export default store;