<template>
  <div :class="containerClass" @click="onWrapperClick">
    <template v-if="$isLoggedIn">
      <div class="layout-topbar bg-primary shadow-7">
        <Button
          class="p-link layout-topbar-button text-white layout-topbar-menu-button my-2 mr-2"
          @click="onMenuToggle"
        >
          <i class="pi pi-bars"></i>
        </Button>
        <router-link to="/" class="layout-topbar-logo flex-grow-none">
          <img src="images/logo-white.png" alt="logo" class="my-5" />
          <span class="text-white text-sm mt-3">for GTEC</span>
        </router-link>
        <div class="layout-topbar-menu flex-grow-1 justify-content-between">
          <div class="flex">
            <template v-for="(item, i) of navbarTopLeftItems" :key="i">
              <router-link :to="item.to">
                <Button
                  :label="item.label"
                  :icon="item.icon"
                  class="p-button-text text-white page-button"
                />
              </router-link>
            </template>
          </div>
          <SplitButton
            class="layout-menu-user-button"
            icon="pi pi-user"
            :model="appBarActionMenu"
          >
            <router-link to="/account">
              <Avatar
                shape="circle"
                class="bg-primary text-white"
                size="large"
                icon="pi pi-user"
              />
            </router-link>
          </SplitButton>
        </div>
      </div>
      <div class="layout-sidebar" @click="onSidebarClick">
        <div
          v-if="$UserPhoto"
          :style="$UserPhoto ? 'height: 140px;overflow: hidden;' : ''"
          class="text-center surface-100 mb-3"
        >
          <router-link to="/account">
            <img
              style="max-width: 100%"
              :src="$utils.setImgUrl($UserPhoto, 'medium')"
            />
          </router-link>
        </div>
        <div v-else>
          <div class="mb-3 mt-2">
            <div
              v-show="!leftDrawerMini"
              class="text-primary font-bold text- px-4 py-2"
            >
              You're welcome, @{{ $UserName }}
            </div>
          </div>
        </div>
        <AppMenu
          :model="navbarSideLeftItems"
          @menuitem-click="onMenuItemClick"
        />
      </div>
    </template>
    <template v-else>
      <div class="layout-topbar bg-primary text-white shadow-7">
        <router-link to="/" class="layout-topbar-logo flex-grow-none">
          <img src="images/logo-white.png" alt="logo" class="my-5" />
          <span class="text-white text-sm mt-3">for GTEC</span>
        </router-link>
      </div>
    </template>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
    </div>
    <!-- App right drawer -->
    <Sidebar
      position="right"
      class="p-sidebar-md"
      :style="{ width: rightDrawerWidth }"
      v-model:visible="rightDrawer"
    >
      <component
        v-if="rightDrawer"
        is-sub-page
        :is="componentFile"
        :api-path="pageUrl"
        :model-bind="modelBind"
        :page-data="pageData"
      ></component>
    </Sidebar>
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
    <!-- Page display dialog -->
    <Dialog
      class="card px-0"
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      :style="{ width: dialogProps.width, maxWidth: dialogProps.maxWidth }"
      :modal="!dialogProps.seamless"
      :maximized="dialogProps.maximized"
      :dismissableMask="!dialogProps.persistent"
      :position="dialogProps.position"
      v-model:visible="pageDialog"
      :showHeader="false"
    >
      <Button
        v-if="dialogProps.closeBtn"
        @click="pageDialog = false"
        style="position: absolute; right: 15px; top: 5px"
        icon="pi pi-times"
        class="p-button-rounded p-button-text p-button-plain"
      />
      <component
        v-if="pageDialog"
        is-sub-page
        :is="componentFile"
        :api-path="pageUrl"
        :model-bind="modelBind"
        :page-data="pageData"
      ></component>
    </Dialog>
    <!-- image preview dialog-->
    <Dialog
      header="&nbsp;"
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      style="width: 50vw"
      v-model:visible="imageViewerDialog"
      dismissableMask
      modal
    >
      <Carousel
        :value="imageViewerImages"
        :circular="true"
        :page="imageViewerCurrentSlide"
      >
        <template #item="{ data }">
          <img style="max-width: 100%; max-height: 100%" :src="data" />
        </template>
      </Carousel>
    </Dialog>
    <!-- request error dialog -->
    <Dialog
      modal
      v-model:visible="errorDialog"
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      style="width: 30vw"
      position="top"
    >
      <template #header>
        <div class="flex align-items-center">
          <div class="mr-2">
            <Avatar
              size="large"
              class="bg-pink-50 text-pink-600"
              icon="pi pi-exclamation-triangle"
            />
          </div>
          <div class="flex text-md text-pink-600 font-bold">
            <!-- {{ $t("unable_to_complete_request") }} -->
            Ooops!
          </div>
        </div>
      </template>
      <div class="">
        <div
          class="text-grey-500 text-md"
          v-for="(msg, index) in pageErrors"
          :key="index"
        >
          <div class="card text-pink-600">{{ msg }}</div>
        </div>
      </div>
    </Dialog>
    <ConfirmDialog></ConfirmDialog>
    <Toast position="top-center" />
  </div>
</template>
<script>
import { defineAsyncComponent, defineComponent, ref, computed } from "vue";
import AppMenu from "./components/AppMenu.vue";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    AppMenu: AppMenu,
  },
  data: () => ({
    layoutMode: "static",
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,
  }),
  computed: {
    ...mapState("pageComponents", [
      "pageComponent",
      "pageUrl",
      "pageData",
      "modelBind",
      "imageViewerImages",
      "dialogProps",
    ]),
    appBarActionMenu() {
      return [
        {
          to: "/account",
          label: "My Account",
          icon: "pi pi-user",
        },
        {
          label: "Logout",
          icon: "pi pi-window-minimize",
          command: (event) => {
            this.startLogout();
          },
        },
      ];
    },
    pageDialog: {
      get: function () {
        return this.$store.state.pageComponents.pageDialog;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setPageDialog", value);
      },
    },
    errorDialog: {
      get: function () {
        return this.pageErrors.length > 0;
      },
      set: function (newValue) {
        this.pageErrors = [];
      },
    },
    imageViewerCurrentSlide: {
      get: function () {
        return this.$store.state.pageComponents.imageViewerCurrentSlide;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setImageViewerCurrentSlide", value);
      },
    },
    imageViewerDialog: {
      get: function () {
        return this.$store.state.pageComponents.imageViewerDialog;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setImageViewerDialog", value);
      },
    },
    rightDrawer: {
      get: function () {
        return this.$store.state.pageComponents.rightDrawer;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setRightDrawer", value);
      },
    },
    rightDrawerWidth: function () {
      if (this.$platform.isDesktop) {
        return this.$store.state.pageComponents.rightDrawerWidth;
      }
      return 320;
    },
    pageErrors: {
      get: function () {
        return this.$store.state.pageComponents.pageErrors;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setPageErrors", value);
      },
    },
    componentFile() {
      if (this.pageComponent) {
        return defineAsyncComponent(() =>
          import(`./pages/${this.pageComponent}.vue`)
        );
      }
      return null;
    },
    containerClass() {
      if (!this.$isLoggedIn) {
        this.staticMenuInactive = true;
        this.mobileMenuActive = false;
      }
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": false,
        },
      ];
    },
    navbarSideLeftItems() {
      let menus = this.$menus.navbarSideLeftItems.filter((menu) => {
        return this.$can.view(menu.to);
      });
      // return this.$menus.navbarSideLeftItems;
      return menus;
    },
    navbarTopLeftItems() {
      let menus = this.$menus.navbarTopLeftItems.filter((menu) => {
        return this.$can.view(menu.to);
      });
      // return this.$menus.navbarTopLeftItems;
      return menus;
    },
    navbarTopRightItems() {
      let menus = this.$menus.navbarTopRightItems.filter((menu) => {
        return this.$can.view(menu.to);
      });
     // return this.$menus.navbarTopRightItems;
      return menus;
    },
  },
  created: function () {
    // Add a request interceptor
    this.$api.axios().interceptors.request.use(
      (config) => {
        this.$store.commit("pageComponents/setPageErrors", []);
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    this.$api.axios().interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.request.status == 401) {
          if (this.$route.name != "index") {
            this.startLogout();
            return;
          }
        } else if (error.request.status == 403) {
          this.$router.push("/error/forbidden");
          return;
        }
        // reject error. Error will be handle by calling page.
        throw error;
      }
    );
  },
  watch: {
    $route(to, from) {
      //clear any errors from previous page
      this.$store.commit("pageComponents/setPageErrors", []);

      //close dialogs open from previous page
      this.$store.commit("pageComponents/setRightDrawer", false);
      this.$store.commit("pageComponents/setPageDialog", false);
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    isDesktop() {
      return window.innerWidth >= 992;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
    },
    toggleUserMenuPanel(event) {
      this.$refs.menuuserpanel.toggle(event);
    },
    startLogout() {
      this.logout();
      window.location = "/";
      //this.$router.go("/"); //force reload of the page
    },
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
